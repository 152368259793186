import React from 'react';
import PropTypes from 'prop-types';

const ConfirmDialog = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText = '確認',
  cancelButtonText = 'キャンセル'
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-sm rounded shadow-lg p-4">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <p className="mb-6 whitespace-pre-line">{message}</p>
        <div className="flex justify-end space-x-2">
          {onCancel && (
            <button
              onClick={onCancel}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              {cancelButtonText}
            </button>
          )}
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string // nullを許容
};

export default ConfirmDialog;