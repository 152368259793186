// QuizSection.js

import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ref, onValue, set, remove, push, get, serverTimestamp } from 'firebase/database';
import { database } from './config/firebase';
import { Trash2, PlusCircle, Check, AlertTriangle, X, ArrowLeft, FileText, ArrowRight } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ConfirmDialog from './ConfirmDialog';


// 教科データ
const SUBJECTS = [
  { id: 'english', name: '英語' },
  { id: 'math', name: '数学' },
  { id: 'japanese', name: '国語' },
  { id: 'science', name: '理科' },
  { id: 'social', name: '社会' },
];

// 教科名取得関数
const getSubjectName = (subjectId) => {
  const subject = SUBJECTS.find((s) => s.id === subjectId);
  return subject ? subject.name : subjectId;
};

















// 英文前処理関数
function preprocessEnglishSentence(sentence) {
  const lowercaseFirst = sentence.charAt(0).toLowerCase() + sentence.slice(1);
  const lastPunctMatch = lowercaseFirst.match(/[,.:;!?]$/);
  const lastPunct = lastPunctMatch ? lastPunctMatch[0] : null;
  const textWithoutLastPunct = lastPunct ? lowercaseFirst.slice(0, -1).trim() : lowercaseFirst.trim();
  const words = textWithoutLastPunct.split(/\s+/).reduce((acc, word) => {
    const matches = word.match(/^(.*?)([,.:;!?]*)$/);
    if (matches) {
      const [, baseWord, punctuation] = matches;
      if (baseWord) acc.push(baseWord);
      if (punctuation && punctuation !== lastPunct) acc.push(punctuation);
    } else {
      acc.push(word);
    }
    return acc;
  }, []);
  return { words, lastPunctuation: lastPunct, sentence: sentence.trim() };
}

// 配列シャッフル関数
function shuffleArray(array) {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

// MultipleChoiceQuizコンポーネント
const MultipleChoiceQuiz = ({ question, answered, handleSubmit, quickAnswerMode }) => {
  const [selectedOption, setSelectedOption] = useState('');

  // クイック回答モード用の処理
  const handleOptionSelect = (choice) => {
    if (answered) return;
    setSelectedOption(choice);
    
    // クイックモードの場合、選択と同時に回答を送信
    if (quickAnswerMode === 'quick') {
      const isCorrect = choice === question.answer;
      handleSubmit(isCorrect, question.explanation, question.answer, choice);
    }
  };

  // 通常モード用の回答送信処理
  const onSubmit = () => {
    if (answered) return;
    const isCorrect = selectedOption === question.answer;
    handleSubmit(isCorrect, question.explanation, question.answer, selectedOption);
  };

  return (
    <div className={answered ? 'opacity-70' : ''}>
      <h3 className="text-xl font-semibold text-purple-900 mb-4">{question.question}</h3>
      <div className="flex flex-col gap-2">
        {question.choices.map((choice, index) => (
          <button
            key={index}
            onClick={() => handleOptionSelect(choice)}
            className={`w-full px-4 py-2 rounded 
              ${answered 
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                : selectedOption === choice 
                  ? 'bg-purple-600 text-white' 
                  : 'bg-purple-100 text-purple-700 hover:bg-purple-200'}`}
            disabled={answered}
            aria-label={`選択肢 ${choice}`}
          >
            {choice}
          </button>
        ))}
      </div>

      {/* 標準モードの場合のみ回答ボタンを表示 */}
      {quickAnswerMode !== 'quick' && (
        <div className="flex justify-end mt-4">
          <button
            onClick={onSubmit}
            className={`px-4 py-2 rounded 
              ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-purple-600 text-white hover:bg-purple-700'}`}
            disabled={answered || selectedOption === ''}
            aria-label="回答を送信するボタン"
          >
            回答する
          </button>
        </div>
      )}
    </div>
  );
};

MultipleChoiceQuiz.propTypes = {
  question: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  quickAnswerMode: PropTypes.oneOf(['standard', 'quick']).isRequired,
};

// TrueFalseQuizコンポーネント
const TrueFalseQuiz = ({ question, answered, handleSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  
  useEffect(() => {
    setSelectedOption(null);
  }, [question.id]);

  // 選択肢が選ばれた時に即座に回答を送信
  const handleOptionSelect = (value) => {
    if (answered) return;
    setSelectedOption(value);
    const isCorrect = value === question.answer;
    handleSubmit(isCorrect, question.explanation, question.answer, value);
  };

  return (
    <div className={answered ? 'opacity-70' : ''}>
      <h3 className="text-xl font-semibold text-purple-900 mb-4">{question.question}</h3>
      <div className="flex flex-col gap-2">
        <button
          onClick={() => handleOptionSelect(true)}
          className={`w-full px-4 py-2 rounded 
          ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                    : selectedOption === true 
                      ? 'bg-purple-600 text-white' 
                      : 'bg-purple-100 text-purple-700 hover:bg-purple-200'}`}
          disabled={answered}
          aria-label="正しいボタン"
        >
          正しい
        </button>
        <button
          onClick={() => handleOptionSelect(false)}
          className={`w-full px-4 py-2 rounded 
          ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                    : selectedOption === false 
                      ? 'bg-purple-600 text-white' 
                      : 'bg-purple-100 text-purple-700 hover:bg-purple-200'}`}
          disabled={answered}
          aria-label="誤りボタン"
        >
          誤り
        </button>
      </div>
    </div>
  );
};

TrueFalseQuiz.propTypes = {
  question: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

// FillInTheBlankコンポーネント
const FillInTheBlank = ({ question, answered, handleSubmit }) => {
  const [userAnswer, setUserAnswer] = useState('');

  const handleAnswerSubmit = () => {
    if (answered) return;

    const trimmedAnswer = userAnswer.trim();
    const correctAnswer = question.answer.trim();
    const isCorrect = trimmedAnswer.toLowerCase() === correctAnswer.toLowerCase();

    // 回答データの構造を整理
    handleSubmit(
      isCorrect,
      question.explanation || "解答が送信されました。",
      correctAnswer,
      trimmedAnswer,
      {
        type: 'fill-in-the-blank',
        question: question.question,
        answer: question.answer,
        explanation: question.explanation,
        userAnswer: trimmedAnswer
      }
    );

    setUserAnswer('');
  };

  return (
    <div className={answered ? 'opacity-70' : ''}>
      <h3 className="text-xl font-semibold text-purple-900 mb-4">{question.question}</h3>
      <input
        type="text"
        placeholder="答えを入力してください"
        value={userAnswer}
        onChange={(e) => setUserAnswer(e.target.value)}
        className={`w-full p-2 border rounded mb-4 ${answered ? 'cursor-not-allowed' : ''}`}
        disabled={answered}
        aria-label="穴埋め問題の回答入力"
      />
      <div className="flex justify-end">
        <button
          onClick={() => !answered && handleAnswerSubmit()}
          className={`px-4 py-2 rounded 
            ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-purple-600 text-white hover:bg-purple-700'}`}
          disabled={answered || userAnswer.trim() === ''}
          aria-label="回答を送信するボタン"
        >
          回答する
        </button>
      </div>
    </div>
  );
};

FillInTheBlank.propTypes = {
  question: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

// MatchingProblemコンポーネント
const MatchingProblem = ({ question, answered, handleSubmit }) => {
  const [userMatches, setUserMatches] = useState(Array(question.leftItems.length).fill(''));
  const [shuffledRightItems, setShuffledRightItems] = useState([]);

  useEffect(() => {
    const shuffled = shuffleArray([...question.rightItems]);
    setShuffledRightItems(shuffled);
  }, [question.rightItems]);

  const handleChange = (leftIndex, rightItem) => {
    if (answered) return;
    const newMatches = [...userMatches];
    newMatches[leftIndex] = rightItem;
    setUserMatches(newMatches);
  };

  const onSubmit = () => {
    if (answered) return;
    const isCorrect = question.leftItems.every((li, i) => {
      const userRightItem = userMatches[i];
      const cri = question.correctMatches[i];
      const correctRightItem = question.rightItems[cri];
      return userRightItem === correctRightItem;
    });
    const correctAnswer = question.leftItems.map((li, i) => {
      const cri = question.correctMatches[i];
      return `${li} - ${question.rightItems[cri]}`;
    }).join(', ');
    handleSubmit(isCorrect, question.explanation, correctAnswer);
  };

  return (
    <div className={answered ? 'opacity-70' : ''}>
      <h3 className="text-xl font-semibold text-purple-900 mb-4">{question.question}</h3>
      {question.leftItems.map((leftItem, index) => (
        <div key={index} className="mb-4">
          <label className="block text-sm font-medium text-purple-700 mb-1">
            {leftItem}
          </label>
          <select
            value={userMatches[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            className={`w-full p-2 border rounded ${answered ? 'cursor-not-allowed' : ''}`}
            disabled={answered}
            aria-label={`左項目 ${leftItem} の選択肢`}
          >
            <option value="">選択してください</option>
            {shuffledRightItems.map((rightItem, idx) => (
              <option key={idx} value={rightItem}>{rightItem}</option>
            ))}
          </select>
        </div>
      ))}

      <div className="flex justify-end mt-4">
  <button
    onClick={onSubmit}
    className={`px-4 py-2 rounded-lg 
      ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-purple-600 text-white hover:bg-purple-700'}`}
    disabled={answered || userMatches.includes('')}
    aria-label="回答を送信するボタン"
  >
    回答する
  </button>
</div>
    </div>
  );
};

MatchingProblem.propTypes = {
  question: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

// SentenceRearrangementコンポーネント
const SentenceRearrangement = ({ question, answered, handleSubmit }) => {
  const [available, setAvailable] = useState([]);
  const [sentence, setSentence] = useState([]);

  // 初期化
  useEffect(() => {
    const initialWords = question.words.map((word, index) => ({ id: `word-${index}`, content: word }));
    setAvailable(shuffleArray(initialWords));
    setSentence([]);
  }, [question.words]);

  const displayWord = (content) => (/^[,.:;!?]$/.test(content) ? content : content.split(':')[0]);

  // ドラッグ終了時の処理を修正
  const onDragEnd = (result) => {
    if (answered) return;
    
    const { source, destination } = result;
    
    // ドロップ先がない場合は何もしない
    if (!destination) {
      return;
    }

    // 同じ場所にドロップした場合は何もしない
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    try {
      // availableからsentenceへの移動
      if (source.droppableId === 'available' && destination.droppableId === 'sentence') {
        const newAvailable = [...available];
        const [movedItem] = newAvailable.splice(source.index, 1);
        const newSentence = [...sentence];
        newSentence.splice(destination.index, 0, movedItem);
        setAvailable(newAvailable);
        setSentence(newSentence);
      }
      // sentenceからavailableへの移動
      else if (source.droppableId === 'sentence' && destination.droppableId === 'available') {
        const newSentence = [...sentence];
        const [movedItem] = newSentence.splice(source.index, 1);
        const newAvailable = [...available];
        newAvailable.splice(destination.index, 0, movedItem);
        setSentence(newSentence);
        setAvailable(newAvailable);
      }
      // sentence内での移動
      else if (source.droppableId === 'sentence' && destination.droppableId === 'sentence') {
        const newSentence = [...sentence];
        const [movedItem] = newSentence.splice(source.index, 1);
        newSentence.splice(destination.index, 0, movedItem);
        setSentence(newSentence);
      }
    } catch (error) {
      console.error('Drag and drop error:', error);
    }
  };

  // ダブルクリック処理を修正
  const handleDoubleClick = (word, from) => {
    if (answered) return;

    try {
      if (from === 'available') {
        setAvailable(prev => prev.filter(w => w.id !== word.id));
        setSentence(prev => [...prev, word]);
      } else {
        setSentence(prev => prev.filter(w => w.id !== word.id));
        setAvailable(prev => [...prev, word]);
      }
    } catch (error) {
      console.error('Double click error:', error);
    }
  };

  const onSubmit = () => {
  if (answered) return;

  // デバッグ用のログ出力
  console.log('作成された文章:', sentence);
  console.log('正解の文章:', question.sentence);

  try {
    // 生徒の回答を文字列として構築
    const userAnswer = sentence
      .map((word, index) => {
        const content = word.content;
        if (/^[,.:;!?]$/.test(content)) {
          return content; // 句読点はそのまま
        }
        return index === 0 ? content : ' ' + content; // 最初の単語以外にスペースを追加
      })
      .join('') + (question.lastPunctuation || '');

    console.log('提出される回答:', userAnswer);
    
    const isCorrect = userAnswer.toLowerCase() === question.sentence.toLowerCase();
    handleSubmit(
      isCorrect,            // 第1引数: 正誤フラグ (true/false)
      question.explanation, // 第2引数: 解説
      question.sentence,    // 第3引数: 正解（本来の英文）
      userAnswer            // 第4引数: ユーザーの回答（並べ替え結果）
    );
  } catch (error) {
    console.error('回答提出エラー:', error);
    alert('回答の提出中にエラーが発生しました。もう一度お試しください。');
  }
};

  return (
    <div className={answered ? 'opacity-70' : ''}>
      <p className="text-sm font-medium text-purple-900 mb-2">
        日本文の意味になるように、下の英単語を並べ替えなさい。
      </p>
      <p className="text-md text-gray-700 mb-4">{question.japaneseTranslation}</p>
      <p className="text-xs text-right text-gray-500 mb-4">
        ※ドラッグ&ドロップまたはダブルクリックで単語を移動できます
      </p>

      <DragDropContext onDragEnd={onDragEnd}>
        {/* 文章作成エリア */}
        <div className="mb-4 border p-4 rounded min-h-[100px]">
          <h4 className="text-gray-500 mb-2 text-sm text-center">ここに並べてください</h4>
          <Droppable droppableId="sentence" direction="horizontal">
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-wrap gap-2"
              >
                {sentence.map((word, index) => (
                  <Draggable
                    key={word.id}
                    draggableId={word.id}
                    index={index}
                    isDragDisabled={answered}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onDoubleClick={() => handleDoubleClick(word, 'sentence')}
                        className={`px-3 py-1 rounded cursor-pointer 
                          ${/^[,.:;!?]$/.test(word.content) ? 'bg-gray-100 text-gray-700' : 'bg-green-100 text-green-700 hover:bg-green-200'}
                          ${answered ? 'cursor-not-allowed' : ''}`}
                      >
                        {displayWord(word.content)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {question.lastPunctuation && (
                  <div className="px-3 py-1 bg-gray-50 text-gray-500 rounded select-none">
                    {question.lastPunctuation}
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </div>

        {/* 利用可能な単語エリア */}
        <div className="border p-4 rounded mb-4">
          <h4 className="font-semibold text-purple-700 mb-2">利用可能な単語</h4>
          <Droppable droppableId="available" direction="horizontal" isDropDisabled={true}>
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-wrap gap-2"
              >
                {available.map((word, index) => (
                  <Draggable
                    key={word.id}
                    draggableId={word.id}
                    index={index}
                    isDragDisabled={answered}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onDoubleClick={() => handleDoubleClick(word, 'available')}
                        className={`px-3 py-1 rounded cursor-pointer
                          ${/^[,.:;!?]$/.test(word.content) ? 'bg-gray-100 text-gray-700' : 'bg-blue-100 text-blue-700 hover:bg-blue-200'}
                          ${answered ? 'cursor-not-allowed' : ''}`}
                      >
                        {displayWord(word.content)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <div className="flex justify-end mt-2">
        <button
          onClick={onSubmit}
          className={`px-6 py-2 rounded-lg 
            ${answered ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-purple-600 text-white hover:bg-purple-700'}`}
          disabled={answered || sentence.length === 0}
          aria-label="回答を送信するボタン"
        >
          回答する
        </button>
      </div>
    </div>
  );
};

SentenceRearrangement.propTypes = {
  question: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};


const ContentItem = memo(({ 
  content, 
  isTeacher, 
  onUnassign, 
  onViewResults, 
  onOpenContent 
}) => {
  const handleUnassignClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onUnassign(content.id);
  }, [content.id, onUnassign]);

  const handleViewResultsClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onViewResults(content.id, content.title);
  }, [content.id, content.title, onViewResults]);

  const handleContentClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenContent(content.id);
  }, [content.id, onOpenContent]);

  return (
    <div className="p-4 bg-pink-50 rounded shadow">
      <div className="flex justify-between items-start">
        {/* コンテンツ情報部分 */}
        <div className="flex-1">
          <div 
            className="cursor-pointer hover:text-purple-600"
            onClick={handleContentClick}
          >
            <h3 className="text-lg font-semibold text-purple-700">{content.title}</h3>
          </div>
<div className="mt-2">
  <p className="text-sm text-gray-600">教科: {getSubjectName(content.subject)}</p>
  <p className="text-sm text-gray-600">出題順序: {content.randomizeQuizzes ? 'ランダム' : '固定'}</p>
  {content.timeLimitPerQuestion > 0 && !content.freeNavigation && (
    <p className="text-sm text-gray-600">1問あたりの制限時間: {content.timeLimitPerQuestion}秒</p>
  )}
  {content.freeNavigation && content.timeLimitPerContent > 0 && (
    <p className="text-sm text-gray-600">コンテンツ全体の制限時間: {content.timeLimitPerContent}秒</p>
  )}
  <p className="text-sm text-gray-600">問題移動: {content.freeNavigation ? '自由' : '一方向'}</p>
  <p className="text-sm text-gray-600">
    解答表示モード: {content.answerRevealMode === 'immediate' ? '即時表示' : '最後まとめ表示'}
  </p>
  <p className="text-sm text-gray-600">
    回答方式: {(content.quickAnswerMode || 'standard') === 'quick' ? 'クイック回答' : '標準回答'}
    {(content.quickAnswerMode || 'standard') === 'quick' && ' (四択・正誤問題)'}
  </p>

           
            
          </div>
        </div>

        {/* 操作ボタン部分 */}
        <div className="flex items-start gap-2 ml-4">
          <button
            onClick={handleViewResultsClick}
            className="p-2 text-purple-500 hover:text-purple-700 hover:bg-purple-100 rounded"
            aria-label="結果を確認"
          >
            <FileText size={20} />
          </button>
          {isTeacher && (
            <button
              onClick={handleUnassignClick}
              className="p-2 text-pink-500 hover:text-pink-700 hover:bg-pink-100 rounded"
              aria-label="割り当て解除"
            >
              <Trash2 size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

ContentItem.displayName = 'ContentItem';




const QuizOverview = ({ content, onStart, onClose }) => {
  const formatTimeLimit = (seconds) => {
    if (seconds <= 0) return '制限なし';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0 
      ? `${minutes}分${remainingSeconds > 0 ? `${remainingSeconds}秒` : ''}`
      : `${seconds}秒`;
  };

  return (
    <div className="bg-white p-6 rounded-lg">
      <h3 className="text-2xl font-semibold text-purple-800 mb-6">{content.title}</h3>
      
      {/* クイズの基本情報 */}
      <div className="space-y-4 mb-8">
        <div className="bg-purple-50 p-4 rounded-lg">
          <h4 className="font-semibold text-purple-700 mb-2">クイズの概要</h4>
          <ul className="space-y-2 text-gray-700">
            <li>• 教科: {getSubjectName(content.subject)}</li>
            <li>• 問題数: {content.quizzes ? Object.keys(content.quizzes).length : 0}問</li>
            {content.timeLimitPerQuestion > 0 && !content.freeNavigation && (
              <li>• 1問あたりの制限時間: {formatTimeLimit(content.timeLimitPerQuestion)}</li>
            )}
            {content.freeNavigation && content.timeLimitPerContent > 0 && (
              <li>• 全体の制限時間: {formatTimeLimit(content.timeLimitPerContent)}</li>
            )}
          </ul>
        </div>

        {/* クイズのルール説明 */}
        <div className="bg-yellow-50 p-4 rounded-lg">
          <h4 className="font-semibold text-yellow-700 mb-2">ルール</h4>
          <ul className="space-y-2 text-gray-700">
            <li>• 問題の移動: {content.freeNavigation ? '自由に行き来可能' : '一方向のみ'}</li>
            <li>• 解答の表示: {content.answerRevealMode === 'immediate' ? '回答直後に表示' : '全問終了後にまとめて表示'}</li>
            
            <li>• 回答方式: {content.quickAnswerMode === 'quick' 
      ? 'クイック回答（選択と同時に回答確定）' 
      : '標準回答（回答ボタンで確定）'}
      {content.quickAnswerMode === 'quick' && ' - 四択・正誤問題のみ'}
    </li>
            
            
            {content.randomizeQuizzes && (
              <li>• 問題はランダムな順序で出題されます</li>
            )}
          </ul>
        </div>
      </div>

      {/* ボタン群 */}
      <div className="flex justify-end gap-4">
        <button
          onClick={onClose}
          className="px-6 py-2 text-gray-600 hover:text-gray-800 rounded-lg"
        >
          キャンセル
        </button>
        <button
          onClick={onStart}
          className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 flex items-center gap-2"
        >
          <ArrowRight size={20} />
          スタート
        </button>
      </div>
    </div>
  );
};

QuizOverview.propTypes = {
  content: PropTypes.object.isRequired,
  onStart: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};























// QuizSectionコンポーネント
const QuizSection = ({ currentUser, selectedStudent, isTeacher }) => {

  console.log('QuizSection render:', {
    currentUser: currentUser?.id,
    selectedStudent: selectedStudent?.id,
    isTeacher
  });







  // 状態管理
  const [activeSubject, setActiveSubject] = useState('all');
  const [contents, setContents] = useState([]);
  const [assignedContents, setAssignedContents] = useState([]);
  const [assignedContentsDetails, setAssignedContentsDetails] = useState([]);

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [sessionResponses, setSessionResponses] = useState({});
  const [error, setError] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentId, setModalContentId] = useState('');

  const [timeLimit, setTimeLimit] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const timerRef = useRef(null);
  const isTimerActiveRef = useRef(false);
  
  const clearTimerRef = useRef(null);
  const startTimerRef = useRef(null);
  const handleTimeUpRef = useRef(null);
  

  const [freeNavigation, setFreeNavigation] = useState(false);

  const [attemptHistory, setAttemptHistory] = useState([]);

  const studentId = selectedStudent ? selectedStudent.id : currentUser.id;

  const modalContentRef = useRef(null);

  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  };

  const [answerRevealMode, setAnswerRevealMode] = useState('immediate');
  const [showFinalSummary, setShowFinalSummary] = useState(false);

  const [isFinalizingAttempt, setIsFinalizingAttempt] = useState(false);
  const attemptFinalizedRef = useRef(false);
  
  const [startTime, setStartTime] = useState(null);
  
  
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [selectedContentResults, setSelectedContentResults] = useState(null);
  
  const [activeAttemptId, setActiveAttemptId] = useState(null);
  
  const [confirmDialog, setConfirmDialog] = useState(null);

  const [showOverview, setShowOverview] = useState(false);
  const [isQuizActive, setIsQuizActive] = useState(false);






 // ★ 追加: モーダルが開いている間、bodyのスクロールを無効化
 useEffect(() => {
   if (isModalOpen) {
     document.body.style.overflow = 'hidden'; 
   } else {
     document.body.style.overflow = 'auto';
   }
   return () => {
     // コンポーネントがアンマウントされたり、isModalOpenが変化した時に呼ばれるクリーンアップ
     document.body.style.overflow = 'auto';
   };
 }, [isModalOpen]);













const ResultItem = memo(({ result, formatElapsedTime, renderQuestionDetails, isExpanded, onToggle }) => {
  return (
    <div className="bg-pink-50 p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold text-purple-700">
          第{result.attemptNumber}回
        </span>
        <span className="text-sm text-gray-600">
          {new Date(result.timestamp).toLocaleString()}
        </span>
      </div>

      <div className="flex items-center gap-6 mb-4 text-purple-600">
        <div>
          正答率: {result.correctRate.toFixed(1)}%
        </div>
        <div>
          正解数: {result.correctCount}/{result.total}
        </div>
        <div>
          所要時間: {formatElapsedTime(result.elapsedTime)}
        </div>
      </div>

      <button
        onClick={onToggle}
        className="w-full text-left p-2 text-purple-600 hover:text-purple-800 hover:bg-purple-50 rounded transition-colors"
      >
        {isExpanded ? '詳細を閉じる' : '詳細を表示'}
      </button>

      {isExpanded && result.detailedResults && (
        <div className="mt-4 space-y-4">
          {result.detailedResults.map((question, qIndex) => (
            <div key={qIndex} className="bg-white p-4 rounded shadow-sm">
              {renderQuestionDetails(question)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

const ResultsModal = memo(({ isOpen, onClose, results, contentTitle }) => {
  const [expandedResultId, setExpandedResultId] = useState(null);
  const modalRef = useRef(null);



 // ★ 追加: モーダルが開いている間、bodyのスクロールを無効化する
 useEffect(() => {
   if (isOpen) {
     document.body.style.overflow = 'hidden';
   } else {
     document.body.style.overflow = 'auto';
   }
   return () => {
     // 結果モーダルが閉じられたり、アンマウントしたときに呼ばれるクリーンアップで auto に戻す
     document.body.style.overflow = 'auto';
   };
 }, [isOpen]);

















  const formatElapsedTime = useCallback((ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}分${remainingSeconds}秒`;
  }, []);

  const handleToggleDetail = useCallback((resultId) => {
    setExpandedResultId(expandedResultId === resultId ? null : resultId);
  }, [expandedResultId]);

  const renderQuestionDetails = useCallback((question) => {
    const details = question.details;
    const response = details.userResponse;

















  // まずは「時間切れ」なのかをチェック
  let resultText = '';
  let resultClass = '';

  if (response.explanation === "時間切れです。") {
    // 時間切れ
    resultText = '× 時間切れ';
    resultClass = 'text-red-600';
  } else if (question.isCorrect) {
    // 正解
    resultText = '✓ 正解';
    resultClass = 'text-blue-600';
  } else {
    // 不正解
    resultText = '× 不正解';
    resultClass = 'text-red-600';
  }
















    return (
      <div className="bg-white p-4 rounded-lg shadow mb-4">
        <div className="mb-2">
        <span className={`font-semibold ${resultClass}`}>
          {resultText}
        </span>
        </div>

        <div className="space-y-2">
        {/* 問題文表示 */}
          {details.type !== 'sentence-rearrangement' && (
            <p className="text-gray-800">
              <span className="font-medium">問題: </span>
              {question.question}
            </p>
          )}

        {/* 問題タイプ別の表示 */}
          {details.type === 'multiple-choice' && (
            <div className="ml-4">
              <p className="text-sm text-gray-600">選択肢:</p>
              <ul className="list-disc ml-4">
                {details.choices.map((choice, idx) => (
                <li key={idx} className={`
                  ${choice === details.answer ? 'text-blue-600 font-semibold' : ''}
                  ${choice === response.userAnswer && choice !== details.answer ? 'text-green-600' : ''}
                `}>
                  {choice}
                  {choice === details.answer && ' (正解)'}
                  {choice === response.userAnswer && choice !== details.answer && ' (あなたの回答)'}
                </li>
                ))}
              </ul>
            </div>
          )}

          {details.type === 'sentence-rearrangement' && (
            <>
              <p className="text-gray-700 mb-2">
                <span className="font-medium">問題（日本語）: </span>
                {details.japaneseTranslation}
              </p>
            <p className="text-blue-600 mb-2">
              <span className="font-medium text-gray-700">正解: </span>
              {details.englishSentence}
            </p>
            <p className="text-green-600">
              <span className="font-medium text-gray-700">あなたの回答: </span>
              {response.explanation === "時間切れです。" ? 
                "時間切れ" : 
                (response?.userAnswer || '回答なし')
              }
            </p>
            </>
          )}

          {details.type === 'true-false' && (
            <>
            <p className="text-blue-600">
              <span className="font-medium text-gray-700">正解: </span>
              {details.answer ? '正しい' : '誤り'}
            </p>
            <p className="text-green-600">
              <span className="font-medium text-gray-700">回答: </span>
              {response.explanation === "時間切れです。" ? 
                "時間切れ" : 
                (response.userAnswer ? '正しい' : '誤り')
              }
            </p>
            </>
          )}

          {details.type === 'fill-in-the-blank' && (
            <>
            <p className="text-blue-600">
              <span className="font-medium text-gray-700">正解: </span>
              {details.answer}
            </p>
            <p className="text-green-600">
              <span className="font-medium text-gray-700">回答: </span>
              {response.explanation === "時間切れです。" ? 
                "時間切れ" : 
                response.userAnswer
              }
            </p>
            </>
          )}

          {details.type === 'matching' && (
            <>
              <p className="text-gray-700">
                <span className="font-medium">マッチング:</span>
              </p>
              <div className="ml-4">
                {details.leftItems.map((leftItem, idx) => (
                  <div key={idx} className="flex gap-4">
                    <span>{leftItem}</span>
                    <span>→</span>
                  <span>
                    {response.explanation === "時間切れです。" ? 
                      "時間切れ" : (
                        <>
                          <span className="text-green-600">
                            {details.rightItems[response.userAnswer?.[idx] || 0]}
                          </span>
                          {details.correctMatches[idx] !== response.userAnswer?.[idx] && 
                            <span className="text-blue-600">
                              {` (正解: ${details.rightItems[details.correctMatches[idx]]})`}
                            </span>
                          }
                        </>
                      )
                    }
                  </span>
                  </div>
                ))}
              </div>
            </>
          )}

        {/* 解説 */}
          {details.explanation && (
            <div className="mt-2 text-gray-600 bg-gray-50 p-2 rounded">
              <span className="font-medium">解説: </span>
              {details.explanation === "時間切れです。" ? 
                "時間切れのため、解答できませんでした。" : 
                details.explanation}
            </div>
          )}
        </div>
      </div>
    );
  }, []);

  if (!isOpen || !results) return null;

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50" />
      <div 
        ref={modalRef}
        className="relative bg-yellow-50 rounded-lg shadow-lg max-w-4xl w-full m-4 flex flex-col"
        onClick={e => e.stopPropagation()}
      >
        {/* ヘッダー部分 - 固定位置 */}
        <div className="px-6 py-4 bg-yellow-50 rounded-t-lg border-b border-yellow-100">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-purple-800">
              {contentTitle} - 挑戦結果一覧
            </h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        {/* スクロール可能なコンテンツ部分 - 別コンテナ */}
        <div className="flex-1 overflow-hidden rounded-b-lg">
          <div className="px-6 py-4 max-h-[calc(90vh-100px)] overflow-y-auto">
            <div className="space-y-6 pb-4">
              {!results || results.length === 0 ? (
                <p className="text-gray-600 text-center py-4">
                  まだ挑戦履歴はありません
                </p>
              ) : (
                results.map(result => (
                  <ResultItem
                    key={result.timestamp}
                    result={result}
                    formatElapsedTime={formatElapsedTime}
                    renderQuestionDetails={renderQuestionDetails}
                    isExpanded={expandedResultId === result.timestamp}
                    onToggle={() => handleToggleDetail(result.timestamp)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


ResultItem.propTypes = {
  result: PropTypes.object.isRequired,
  formatElapsedTime: PropTypes.func.isRequired,
  renderQuestionDetails: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

ResultsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  results: PropTypes.array,
  contentTitle: PropTypes.string
};

ResultItem.displayName = 'ResultItem';
ResultsModal.displayName = 'ResultsModal';


  
 
 
 
// モーダルを開く処理を最適化

const [resultsData, setResultsData] = useState(null);
const [resultsTitle, setResultsTitle] = useState('');
// メモ化した結果データ
const memoizedResults = useMemo(() => resultsData, [resultsData]);

const handleViewResults = useCallback(async (contentId, contentTitle) => {

console.log('handleViewResults called:', { contentId, contentTitle });

  try {
    const studentId = isTeacher ? selectedStudent.id : currentUser.id;
    const resultsRef = ref(database, `results/${studentId}/${contentId}`);
    const snapshot = await get(resultsRef);
    const data = snapshot.val();

    if (data) {
      // 一度だけソートを行う
      const sortedResults = Object.values(data)
        .map(result => ({
          ...result,
          timestamp: result.timestamp || Date.now(),
          attemptNumber: result.attemptNumber || 1
        }))
        .sort((a, b) => b.timestamp - a.timestamp);

      setResultsData(sortedResults);
      setResultsTitle(contentTitle);
      setShowResultsModal(true);
    } else {
      setResultsData([]);
      setResultsTitle(contentTitle);
      setShowResultsModal(true);
    }
  } catch (error) {
    console.error('結果の取得に失敗しました:', error);
    alert('結果の取得に失敗しました。');
  }
}, [isTeacher, selectedStudent?.id, currentUser?.id]);



const stableSortedResults = useMemo(() => {
  if (!selectedContentResults?.results) return [];
  return [...selectedContentResults.results].sort((a, b) => b.timestamp - a.timestamp);
}, [selectedContentResults?.results]);











const handleCloseResults = useCallback(() => {
  setShowResultsModal(false);
  setSelectedContentResults(null);
}, []);
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  
  
  
  
  
  
  
  // タイマー関連のベース関数
const clearTimer = useCallback(() => {
  if (timerRef.current) {
    clearInterval(timerRef.current);
    timerRef.current = null;
  }
  isTimerActiveRef.current = false;
}, []);
  
  
  // clearTimerRef を更新
// タイマー関連のフック
useEffect(() => {
  clearTimerRef.current = clearTimer;

  // モーダルが表示されているときはタイマーをクリア
  if (showResultsModal) {
    clearTimer();
  }

  return () => {
    if (clearTimerRef.current) {
      clearTimerRef.current();
    }
  };
}, [clearTimer, showResultsModal]); // showResultsModalを依存配列に追加
  


// モーダルを閉じる時の処理も修正
const closeModal = useCallback(() => {
  if (clearTimerRef.current) {
    clearTimerRef.current();
  }
  setIsModalOpen(false);
  setShowFinalSummary(false);
  setShowFeedback(false);
  setSessionResponses({});
  setCurrentQuestionIndex(0);
  setIsFinalizingAttempt(false);
  attemptFinalizedRef.current = false;
}, []);




  
  
  
  
// finalizeAttempt の定義
const finalizeAttempt = useCallback(async (forced = false, responses = sessionResponses) => {
  if (isFinalizingAttempt || attemptFinalizedRef.current) {
    console.log('既に完了処理中または完了済みです');
    return;
  }

  console.log('finalizeAttempt 開始');
  setIsFinalizingAttempt(true);
  attemptFinalizedRef.current = true;

  try {
    const total = questions.length;
    const answeredCount = Object.keys(responses).length;
    
    // タイムオーバーの場合は強制的に全問回答扱いにする
  if (!forced) {
    if (answeredCount < total) {
      console.log('全問未回答のため終了');
      setConfirmDialog({
        title: '注意',
        message: '全ての問題を解いていないため、結果は記録されませんでした。',
        onConfirm: () => {
          setConfirmDialog(null);
          closeModal();
        },
        onCancel: null,
        cancelButtonText: null
      });
      return;
    }
  }


    // タイムオーバーの場合、Firebase保存用のresponsesにも「時間切れ」を反映
    let updatedResponses = { ...responses };
    if (forced) {
      questions.forEach((q) => {
        if (!updatedResponses[q.id]) {
          updatedResponses[q.id] = {
            isCorrect: false,
            explanation: "時間切れです。",
            correctAnswer: q.type === 'sentence-rearrangement' ? q.englishSentence : (q.answer || ""),
            userAnswer: "",
            type: q.type,
            details: q
          };
        }
      });
    }







// データの検証と適切なフォーマットへの変換
Object.keys(updatedResponses).forEach(key => {
  const response = updatedResponses[key];
  const question = questions.find(q => q.id === key);
  
  if (question) {
    if (question.type === 'sentence-rearrangement') {
      // 英文整序問題の場合、正解と回答を文字列として保存
      updatedResponses[key] = {
        ...response,
        correctAnswer: question.englishSentence,
        userAnswer: response.userAnswer || "",
        details: {
          ...question,
          englishSentence: question.englishSentence,
          japaneseTranslation: question.japaneseTranslation
        }
      };
    }
    // Fill-in-the-blank 用の特別な処理を追加
    else if (question.type === 'fill-in-the-blank') {
      updatedResponses[key] = {
        ...response,
        details: {
          type: question.type,
          question: question.question,
          answer: question.answer,
          explanation: question.explanation || "",
          userResponse: {
            isCorrect: response.isCorrect,
            explanation: response.explanation,
            userAnswer: response.userAnswer,
            correctAnswer: question.answer
          }
        }
      };
    }
  }
});












    const correctCount = Object.values(updatedResponses).filter((r) => r.isCorrect).length;
    const correctRate = total > 0 ? (correctCount / total) * 100 : 0;
    const elapsedTime = startTime ? Date.now() - startTime : 0;

    if (clearTimerRef.current) {
      clearTimerRef.current();
    }

    // 各問題の回答詳細を含むデータを作成
    const detailedResults = questions.map(q => {
      const response = updatedResponses[q.id] || {
        isCorrect: false,
        explanation: "時間切れまたは未回答",
        userAnswer: "",
        type: q.type,
        details: q
      };

      // 基本的な詳細情報
      const baseDetails = {
        questionId: q.id,
        questionType: q.type,
        isCorrect: response?.isCorrect || false,
        explanation: response?.explanation || "",
      };
      
      
      // 問題タイプ別の詳細情報
      switch (q.type) {
        case 'multiple-choice':
          return {
            ...baseDetails,
            question: q.question,
            choices: q.choices,
            correctAnswer: q.answer,
            userAnswer: response.userAnswer,
            details: {
              type: q.type,
              choices: q.choices,
              answer: q.answer,
              userResponse: response
            }
          };

case 'sentence-rearrangement':
  return {
    ...baseDetails,
    question: q.japaneseTranslation,
    englishSentence: q.englishSentence,
    japaneseTranslation: q.japaneseTranslation,
    userAnswer: response.userAnswer || "",
    details: {
      type: q.type,
      englishSentence: q.englishSentence,
      japaneseTranslation: q.japaneseTranslation,
      userResponse: response
    }
  };

        case 'true-false':
          return {
            ...baseDetails,
            question: q.question,
            correctAnswer: q.answer,
            userAnswer: response.userAnswer,
            details: {
              type: q.type,
              answer: q.answer,
              userResponse: response
            }
          };

        case 'fill-in-the-blank':
          return {
            ...baseDetails,
            question: q.question,
            correctAnswer: q.answer,
            userAnswer: response.userAnswer,
            details: {
              type: q.type,
              answer: q.answer,
              userResponse: response
            }
          };

        case 'matching':
          return {
            ...baseDetails,
            question: q.question,
            leftItems: q.leftItems,
            rightItems: q.rightItems,
            correctMatches: q.correctMatches,
            userAnswer: response.userAnswer,
            details: {
              type: q.type,
              leftItems: q.leftItems,
              rightItems: q.rightItems,
              correctMatches: q.correctMatches,
              userResponse: response
            }
          };

        default:
          return null;
      }
    }).filter(Boolean); // nullを除外

    // 結果をFirebaseに保存
    const resultsRef = ref(database, `results/${studentId}/${modalContentId}`);
    const snapshot = await get(resultsRef);
    const data = snapshot.val();
    const history = data ? Object.values(data) : [];
    const currentAttemptNumber = history.length + 1;

    const resultRef = push(resultsRef);
    await set(resultRef, {
      attemptNumber: currentAttemptNumber,
      timestamp: serverTimestamp(),
      correctRate,
      correctCount,
      total,
      elapsedTime,
      detailedResults,
      isTimeOver: forced, // タイムオーバーフラグを追加
    });

 // 「全問題の結果」用にsessionResponsesを更新
    if (forced) {
      setSessionResponses(updatedResponses);
    }













    setShowFinalSummary(true);
    
setConfirmDialog({
  title: '挑戦完了',
  message: `${currentAttemptNumber}回目の結果: 正答率${correctRate.toFixed(1)}% (${correctCount}/${total})`,
  onConfirm: () => setConfirmDialog(null),
  onCancel: null, // キャンセルボタンを非表示にする
  cancelButtonText: null
});

    } catch (error) {
      console.error('結果の保存に失敗しました:', error);
      console.error('エラーの詳細:', error.message);
      
setConfirmDialog({
  title: 'エラー',
  message: '結果の保存に失敗しました。',
  onConfirm: () => setConfirmDialog(null),
  onCancel: null, // キャンセルボタンを非表示にする
  cancelButtonText: null
});
      
    } finally {
      setIsFinalizingAttempt(false);
    }
  }, [questions, modalContentId, studentId, answerRevealMode, sessionResponses, closeModal, startTime]);



// 経過時間をフォーマットする関数を追加
const formatElapsedTime = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}分${remainingSeconds}秒`;
};













// handleQuestionSubmit を修正
const handleQuestionSubmit = useCallback((isCorrect, explanation, correctAnswer, userAnswer) => {
  const question = questions[currentQuestionIndex];
  if (!question) return;
  
  // 自由移動モードでは、回答時にタイマーを停止しない
  if (!freeNavigation && clearTimerRef.current) {
    clearTimerRef.current();
  }

  const updatedResponses = {
    ...sessionResponses,
    [question.id]: { 
      isCorrect, 
      explanation, 
      correctAnswer,
      userAnswer,
      type: question.type,
      details: question
    }
  };

  console.log('更新された回答:', updatedResponses);
  setSessionResponses(updatedResponses);

  if (answerRevealMode === 'immediate') {
    setShowFeedback(true);
  } else {
    setShowFeedback(false);
  }

  const allAnswered = questions.every(q => updatedResponses[q.id]);
  const isLastQuestion = currentQuestionIndex === questions.length - 1;



  // 現在のコンテンツの設定を取得
  const content = assignedContentsDetails.find(c => c.id === modalContentId);
  const isQuickAnswerMode = content?.quickAnswerMode === 'quick';
  const isQuickAnswerType = question.type === 'multiple-choice' || question.type === 'true-false';





  if (allAnswered && isLastQuestion && !attemptFinalizedRef.current) {
    console.log('全問回答完了、結果確定処理開始');
    finalizeAttempt(false, updatedResponses);
  }

  setTimeout(scrollToTop, 0);
}, [questions, currentQuestionIndex, answerRevealMode, sessionResponses, finalizeAttempt, freeNavigation]);






  
  
  
  
  
  
  
  
  
  
  
 







const handleTimeUp = useCallback(() => {
  const question = questions[currentQuestionIndex];
  if (!question) return;

  let updatedResponses = { ...sessionResponses };
  
  if (freeNavigation) {
    // フリーナビゲーションモードの処理（変更なし）
    questions.forEach(q => {
      if (!updatedResponses[q.id]) {
        updatedResponses[q.id] = {
          isCorrect: false,
          explanation: "時間切れです。",
          correctAnswer: q.type === 'sentence-rearrangement' ? q.englishSentence : (q.answer || ""),
          userAnswer: "",
          type: q.type,
          details: q
        };
      }
    });

    setSessionResponses(updatedResponses);
    setTimeout(() => {
      finalizeAttempt(true, updatedResponses);
    }, 100);
  } else {
    // 一方向モードの場合
    if (!updatedResponses[question.id]) {
      updatedResponses[question.id] = {
        isCorrect: false,
        explanation: "時間切れです。",
        correctAnswer: question.answer || "",
        userAnswer: "",
        type: question.type,
        details: question
      };
    }

    setSessionResponses(updatedResponses);
    setShowFeedback(true);
    const isLastQuestion = currentQuestionIndex >= questions.length - 1;

    // 全ての問題が終了したとき、または最後の問題のときに結果を保存
    if (Object.keys(updatedResponses).length === questions.length || isLastQuestion) {
      setTimeout(() => {
        finalizeAttempt(true, updatedResponses);
      }, 2000);
    } else {
      // 次の問題に進む前に、現在までの回答を保存
      setTimeout(() => {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < questions.length) {
          setCurrentQuestionIndex(nextIndex);
          setShowFeedback(false);
          if (startTimerRef.current) {
            startTimerRef.current(timeLimit);
          }
          scrollToTop();
        }
      }, 2000);
    }
  }
}, [
  questions,
  currentQuestionIndex,
  sessionResponses,
  freeNavigation,
  timeLimit,
  finalizeAttempt
]);


  // handleTimeUpRef を更新
useEffect(() => {
  handleTimeUpRef.current = handleTimeUp;
}, [handleTimeUp]);
  
  
  
  





const startTimer = useCallback((duration) => {
  clearTimer();
  
  if (duration <= 0) return;
  
  setTimeRemaining(duration);
  isTimerActiveRef.current = true;

  timerRef.current = setInterval(() => {
    setTimeRemaining((prev) => {
      if (prev <= 1) {
        clearTimer();
        if (handleTimeUpRef.current) {
          setTimeout(handleTimeUpRef.current, 0);
        }
        return 0;
      }
      return prev - 1;
    });
  }, 1000);
}, [clearTimer]);


// startTimerRef を更新
useEffect(() => {
  startTimerRef.current = startTimer;
}, [startTimer]);


// 問題の移動を監視するuseEffect
useEffect(() => {
  if (questions.length > 0 && currentQuestionIndex >= questions.length) {
    // インデックスが範囲外の場合、自動的に修正
    setCurrentQuestionIndex(Math.min(currentQuestionIndex, questions.length - 1));
  }
}, [questions.length, currentQuestionIndex]);


  
  
  
  
  

  // 状態の変化を監視してデバッグログを出力
  useEffect(() => {
    console.log('sessionResponses が更新されました:', sessionResponses);
    console.log('showFeedback の状態:', showFeedback);
  }, [sessionResponses, showFeedback]);

  // コンテンツデータの取得
  useEffect(() => {
    const contentsRef = ref(database, 'contents');
    const unsubscribe = onValue(contentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const contentList = Object.entries(data).map(([id, content]) => ({
          id,
          ...content,
          quizzes: content.quizzes
            ? Object.entries(content.quizzes).map(([qid, quiz]) => ({
                id: qid,
                ...quiz,
              }))
            : [],
        }));
        setContents(contentList);
      } else {
        setContents([]);
      }
    });
    return () => unsubscribe();
  }, []);

  // 割り当てられたコンテンツの取得
  useEffect(() => {
    if (!studentId) return;
    const assignedRef = ref(database, `studentAssignedContents/${studentId}`);
    const unsubscribe = onValue(assignedRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setAssignedContents(Object.keys(data));
      } else {
        setAssignedContents([]);
      }
    });
    return () => unsubscribe();
  }, [studentId]);

  // 割り当てられたコンテンツの詳細取得
  useEffect(() => {
    if (assignedContents.length === 0) {
      setAssignedContentsDetails([]);
      return;
    }
    const contentsRef = ref(database, 'contents');
    const unsubscribe = onValue(contentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const assignedDetails = assignedContents
          .map((contentId) => (data[contentId] ? { id: contentId, ...data[contentId] } : null))
          .filter(Boolean);
        setAssignedContentsDetails(assignedDetails);
      } else {
        setAssignedContentsDetails([]);
      }
    });
    return () => unsubscribe();
  }, [assignedContents]);
  
  
  
// クリーンアップ用のuseEffect
useEffect(() => {
  return () => {
    if (clearTimerRef.current) {
      clearTimerRef.current();
    }
  };
}, []);

// 問題の移動を監視するuseEffect
useEffect(() => {

  if (!isQuizActive) return;

  if (questions.length > 0 && currentQuestionIndex >= questions.length) {
    // インデックスが範囲外の場合、自動的に修正
    setCurrentQuestionIndex(Math.min(currentQuestionIndex, questions.length - 1));
  }

  // 自由移動モードでない場合のみ、問題切り替え時にタイマーを再開始
  if (!freeNavigation && timeLimit > 0) {
    startTimer(timeLimit);
  }
}, [questions.length, currentQuestionIndex, freeNavigation, timeLimit, startTimer]);
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  

// モーダルを開く前にsessionResponsesを初期化する（新しい挑戦開始時）
const handleOpenContent = async (contentId) => {
  setModalContentId(contentId);
  setIsModalOpen(true);
  setShowOverview(true);
  setIsQuizActive(false);
  setSessionResponses({});
  setShowFinalSummary(false);
  setShowFeedback(false);
  setCurrentQuestionIndex(0);
  attemptFinalizedRef.current = false;
//  setStartTime(Date.now());
  
  const resultsRef = ref(database, `results/${studentId}/${contentId}`);
  const snapshot = await get(resultsRef);
  const data = snapshot.val();
  if (data) {
    const history = Object.values(data)
      .map((entry) => entry)
      .sort((a, b) => a.attemptNumber - b.attemptNumber);
    setAttemptHistory(history);
  } else {
    setAttemptHistory([]);
  }
};

// クイズを開始する関数
const handleStartQuiz = () => {
  setShowOverview(false);
  setIsQuizActive(true);
  setStartTime(Date.now());
  
  const content = assignedContentsDetails.find(c => c.id === modalContentId);
  if (content?.freeNavigation && content?.timeLimitPerContent > 0) {
    startTimer(content.timeLimitPerContent);
  } else if (!content?.freeNavigation && content?.timeLimitPerQuestion > 0) {
    startTimer(content.timeLimitPerQuestion);
  }
};








// コンテンツを開いたときに質問を取得するuseEffect
useEffect(() => {
  if (!modalContentId || !isModalOpen) {
    setQuestions([]);
    return;
  }

  const contentRef = ref(database, `contents/${modalContentId}`);
  const unsubscribe = onValue(contentRef, (snapshot) => {
    const content = snapshot.val();
    if (content && content.quizzes) {
      let quizzes = Object.entries(content.quizzes).map(([quizId, quiz]) => ({
        id: quizId,
        contentId: modalContentId,
        contentTitle: content.title,
        ...quiz
      }));

      // quizzes配列の処理
      quizzes = quizzes.map(quiz => {
        if (quiz.type === 'sentence-rearrangement') {
          const processed = preprocessEnglishSentence(quiz.englishSentence || quiz.sentence || '');
          return {
            ...quiz,
            words: processed.words,
            lastPunctuation: processed.lastPunctuation,
            sentence: processed.sentence
          };
        }
        return quiz;
      });

      if (activeSubject !== 'all') {
        quizzes = quizzes.filter((q) => q.subject === activeSubject);
      }

      // タイムスタンプでソート
      quizzes.sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));

      // ランダム出題の処理
      if (content.randomizeQuizzes) {
        quizzes = shuffleArray([...quizzes]);
      }

      // タイマー設定
      const totalTime = content.freeNavigation 
        ? (content.timeLimitPerContent || 0) 
        : (content.timeLimitPerQuestion || 0);
      
      setFreeNavigation(!!content.freeNavigation);
      setTimeLimit(totalTime);
      setAnswerRevealMode(content.answerRevealMode || 'immediate');
      setQuestions(quizzes);

 
    } else {
      setQuestions([]);
      console.log('No quizzes found in content'); // デバッグ用
    }
  });

  return () => {
    unsubscribe();
    clearTimer();
  };
}, [modalContentId, isModalOpen, activeSubject, clearTimer, startTimer]);


useEffect(() => {
  if (Object.keys(sessionResponses).length > 0) {
    // answerRevealMode が immediate の場合のみフィードバックを表示
    if (answerRevealMode === 'immediate') {
      setShowFeedback(true);
    }
  }
}, [sessionResponses, answerRevealMode]);



















const handleAssignQuiz = useCallback(async () => {
  if (!selectedStudent) {
    setConfirmDialog({
      title: 'エラー',
      message: 'クイズを割り当てる生徒を選択してください。',
      onConfirm: () => setConfirmDialog(null),
      onCancel: null, // キャンセルボタンを非表示
      cancelButtonText: null
    });
    return;
  }
  
  if (!selectedContentId) {
    setConfirmDialog({
      title: 'エラー',
      message: '割り当てるコンテンツを選択してください。',
      onConfirm: () => setConfirmDialog(null),
      onCancel: null,
      cancelButtonText: null
    });
    return;
  }

  const selectedContent = contents.find((c) => c.id === selectedContentId);
  if (!selectedContent) {
    setConfirmDialog({
      title: 'エラー',
      message: '選択されたコンテンツが見つかりません。',
      onConfirm: () => setConfirmDialog(null),
      onCancel: null,
      cancelButtonText: null
    });
    return;
  }

  setConfirmDialog({
    title: 'コンテンツの割り当て',
    message: `コンテンツ「${selectedContent.title}」を割り当てますか？`,
    onConfirm: async () => {
      try {
        const assignRef = ref(database, `studentAssignedContents/${studentId}/${selectedContentId}`);
        await set(assignRef, true);
        setSelectedContentId('');
        setIsAssigning(false);
        
        setConfirmDialog({
          title: '成功',
          message: 'コンテンツが割り当てられました。',
          onConfirm: () => setConfirmDialog(null),
          onCancel: null,
          cancelButtonText: null
        });
      } catch (error) {
        console.error('割り当て失敗:', error);
        setConfirmDialog({
          title: 'エラー',
          message: '割り当てに失敗しました。',
          onConfirm: () => setConfirmDialog(null),
          onCancel: null,
          cancelButtonText: null
        });
      }
    },
    onCancel: () => setConfirmDialog(null)
  });
}, [studentId, selectedStudent, contents, selectedContentId]);


  // コンテンツの割り当てを解除する関数
const handleUnassignContentClick = useCallback((contentId) => {
  console.log('handleUnassignContentClick called with:', contentId);

  // confirmDialogの状態を直接更新
  setConfirmDialog(() => ({
    title: 'コンテンツの割り当て解除',
    message: 'このコンテンツの割り当てを解除しますか？',
    onConfirm: async () => {
      try {
        console.log('Attempting to remove content:', contentId); // デバッグ用
        await remove(ref(database, `studentAssignedContents/${studentId}/${contentId}`));
        await remove(ref(database, `results/${studentId}/${contentId}`));
        
        setConfirmDialog(() => ({
          title: '成功',
          message: 'コンテンツの割り当てが解除されました。',
          onConfirm: () => {
            console.log('Success dialog confirmed'); // デバッグ用
            setConfirmDialog(null);
          },
          cancelButtonText: null
        }));
      } catch (error) {
        console.error('解除失敗:', error);
        setConfirmDialog(() => ({
          title: 'エラー',
          message: '解除に失敗しました。',
          onConfirm: () => setConfirmDialog(null),
          cancelButtonText: null
        }));
      }
    },
    onCancel: () => {
      console.log('Dialog canceled'); // デバッグ用
      setConfirmDialog(null);
    }
  }));
}, [studentId]);


useEffect(() => {
  console.log('confirmDialog state changed:', confirmDialog);
}, [confirmDialog]);

  // 科目変更ハンドラー
  const handleSubjectChange = useCallback((subjectId) => {
    setActiveSubject(subjectId);
  }, []);

 

  // 質問のレンダリング
  const renderQuestion = () => {
    const question = questions[currentQuestionIndex];
    const answered = !!(question && sessionResponses[question.id]);
    
  // 現在のコンテンツの設定を取得
    const content = assignedContentsDetails.find(c => c.id === modalContentId);
    const quickAnswerMode = content?.quickAnswerMode || 'standard';
    
    

    if (!question) return <p className="text-gray-600">このコンテンツにはクイズがありません。</p>;
    switch (question.type) {
    case 'multiple-choice':
      return (
        <MultipleChoiceQuiz 
          question={question} 
          answered={answered} 
          handleSubmit={handleQuestionSubmit}
          quickAnswerMode={quickAnswerMode}
        />
      );
    case 'true-false':
      return (
        <TrueFalseQuiz 
          question={question} 
          answered={answered} 
          handleSubmit={handleQuestionSubmit}
          quickAnswerMode={quickAnswerMode}
        />
      );
      case 'fill-in-the-blank':
        return <FillInTheBlank question={question} answered={!!answered} handleSubmit={handleQuestionSubmit} />;
      case 'matching':
        return <MatchingProblem question={question} answered={!!answered} handleSubmit={handleQuestionSubmit} />;
      case 'sentence-rearrangement':
        return <SentenceRearrangement question={question} answered={!!answered} handleSubmit={handleQuestionSubmit} />;
      default:
        return <p className="text-gray-600">不明なクイズ形式です。</p>;
    }
  };

return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-100 border border-red-200 text-red-700 p-4 rounded mb-6">
          <p>{error}</p>
          <button
            onClick={() => setError(null)}
            className="mt-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
          >
            再試行
          </button>
        </div>
      )}

      {/* 1. タイトル部分 */}
	  <h2 className="text-xl font-semibold text-purple-800 mb-4">
	    {selectedStudent ? `${selectedStudent.name}のコンテンツ` : 
	     currentUser.role === 'student' ? `${currentUser.name}のコンテンツ` : 
	     '割り当てられたコンテンツ'}
	  </h2>

      {/* 2. 教師が生徒にクイズを割り当てるUI部分 */}
      {isTeacher && selectedStudent && (
        <div className="mb-6">
          {!isAssigning ? (
            <button
              onClick={() => setIsAssigning(true)}
              className="w-full py-4 bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200 flex items-center justify-center gap-2"
              aria-label="クイズを割り当てるボタン"
            >
              <PlusCircle size={20} />
              クイズを割り当て
            </button>
          ) : (
            <div className="mt-6 p-4 bg-yellow-50 rounded shadow">
              <h3 className="text-xl font-semibold text-purple-700 mb-4">クイズを割り当てる</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-purple-700 mb-1" htmlFor="content-select">
                    コンテンツを選択
                  </label>
                  <select
                    id="content-select"
                    value={selectedContentId}
                    onChange={(e) => setSelectedContentId(e.target.value)}
                    className="w-full p-2 border rounded"
                    aria-label="コンテンツ選択ドロップダウン"
                  >
                    <option value="">コンテンツを選択</option>
                    {contents.map((content) => (
                      <option key={content.id} value={content.id}>
                        {content.title} ({getSubjectName(content.subject)})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end gap-2">
                  <button
   onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    handleAssignQuiz();
  }}
  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
  disabled={!selectedContentId}
  aria-label="クイズを割り当てるボタン"
                  >
                    割り当て
                  </button>
                  <button
                    onClick={() => {
                      setIsAssigning(false);
                      setSelectedContentId('');
                    }}
                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    aria-label="キャンセルボタン"
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* 3. 科目選択ボタン */}
      <div className="flex flex-wrap gap-2">
        {[{ id: 'all', name: '全教科' }, ...SUBJECTS].map((subject) => (
          <button
            key={subject.id}
            onClick={() => setActiveSubject(subject.id)}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
              activeSubject === subject.id
                ? 'bg-purple-500 text-white'
                : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
            }`}
            aria-label={`教科選択ボタン ${subject.name}`}
          >
            {subject.name}
          </button>
        ))}
      </div>

{/* 4. 割り当てられたコンテンツの表示 */}
<div className="mb-6">
{assignedContentsDetails.length > 0 ? (
  <div className="space-y-4">
    {assignedContentsDetails
      .filter(content => activeSubject === 'all' || content.subject === activeSubject)
      .map((content) => (
    <div key={content.id} className="p-4 bg-pink-50 rounded shadow">
      <div className="flex justify-between items-start">

           {/* コンテンツ表示部分 */}
            <div className="flex-1 cursor-pointer" onClick={() => handleOpenContent(content.id)}>
              <h3 className="text-lg font-semibold text-purple-700 hover:text-purple-600">
                {content.title}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-600">教科: {getSubjectName(content.subject)}</p>
                 <p className="text-sm text-gray-600">出題順序: {content.randomizeQuizzes ? 'ランダム' : '固定'}</p>
                {content.timeLimitPerQuestion > 0 && !content.freeNavigation && (
                  <p className="text-sm text-gray-600">1問あたりの制限時間: {content.timeLimitPerQuestion}秒</p>
                )}
                {content.freeNavigation && content.timeLimitPerContent > 0 && (
                  <p className="text-sm text-gray-600">コンテンツ全体の制限時間: {content.timeLimitPerContent}秒</p>
                )}
                <p className="text-sm text-gray-600">問題移動: {content.freeNavigation ? '自由' : '一方向'}</p>
                <p className="text-sm text-gray-600">
                  解答表示モード: {content.answerRevealMode === 'immediate' ? '即時表示' : '最後まとめ表示'}
                </p>


                <p className="text-sm text-gray-600">
                  回答方式: {(content.quickAnswerMode || 'standard') === 'quick' ? 'クイック回答' : '標準回答'}
                  {(content.quickAnswerMode || 'standard') === 'quick' && ' (四択・正誤問題)'}
                </p>
              </div>
            </div>

{/* ボタン部分 - 独立したdivで分離 */}
<div className="flex items-start gap-2 ml-4">
  <button
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      handleViewResults(content.id, content.title);
    }}
    className="text-purple-500 hover:text-purple-700 p-2 rounded"
  >
    <FileText size={20} />
  </button>
  {isTeacher && (
    <button
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm('このコンテンツの割り当てを解除しますか？')) {
          try {
            await remove(ref(database, `studentAssignedContents/${studentId}/${content.id}`));
            await remove(ref(database, `results/${studentId}/${content.id}`));
            alert('コンテンツの割り当てが解除されました。');
          } catch (error) {
            console.error('解除失敗:', error);
            alert('解除に失敗しました。');
          }
        }
      }}
      className="text-pink-500 hover:text-pink-700 p-2 rounded"
      aria-label="割り当て解除"
    >
      <Trash2 size={20} />
    </button>
  )}


            </div>
          </div>
        </div>
      ))}
  </div>
) : (
  <p className="text-gray-600">現在、割り当てられたコンテンツはありません。</p>
)}





{/* 結果表示モーダル */}
{showResultsModal && (
  <ResultsModal
    isOpen={showResultsModal}
    onClose={() => {
      setShowResultsModal(false);
      setResultsData(null);
    }}
    results={memoizedResults}
    contentTitle={resultsTitle}
  />
)}
        
        
    {confirmDialog && (
      <ConfirmDialog
        title={confirmDialog.title}
        message={confirmDialog.message}
        onConfirm={confirmDialog.onConfirm}
        onCancel={confirmDialog.onCancel}
        confirmButtonText={confirmDialog.confirmButtonText}
        cancelButtonText={confirmDialog.cancelButtonText}
      />
    )}
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
      </div>

{/* モーダル部分 */}
{isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="fixed inset-0 bg-black opacity-50" aria-label="モーダル背景" />
    
    {showOverview ? (
      // 概要モーダルは中央配置のまま
      <div className="relative bg-white rounded-lg shadow-lg max-w-3xl w-full m-4 max-h-[90vh] overflow-y-auto">
        <QuizOverview
          content={assignedContentsDetails.find(c => c.id === modalContentId)}
          onStart={handleStartQuiz}
          onClose={closeModal}
        />
      </div>
    ) : isQuizActive && (
      // クイズ画面は全画面表示
      <div className="fixed inset-0 bg-white flex flex-col overflow-y-auto">
        <div className="max-w-4xl w-full mx-auto p-6 flex-1">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-purple-800">
              {assignedContentsDetails.find((c) => c.id === modalContentId)?.title}
            </h2>
            <button
              onClick={() => closeModal()}
              className="text-gray-500 hover:text-gray-700"
              aria-label="モーダルを閉じるボタン"
            >
              <X size={24} />
            </button>
          </div>

{/* プログレスバー */}
          <div className="mb-6">
            <div className="w-full bg-purple-100 rounded-full h-2.5">
              <div
                className="bg-purple-600 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
              />
            </div>
            <p className="text-right text-sm text-purple-600 mt-1">
              {currentQuestionIndex + 1} / {questions.length}問
            </p>
          </div>

          {/* 問題インジケーター */}
          {freeNavigation && (
            <div className="mb-4 flex flex-wrap gap-2 justify-center">
              {questions.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setCurrentQuestionIndex(index);
                    setShowFeedback(false);
                    scrollToTop();
                  }}
                  className={`w-8 h-8 rounded-full flex items-center justify-center
                    ${currentQuestionIndex === index
                      ? 'bg-purple-600 text-white'
                      : sessionResponses[questions[index]?.id]
                        ? 'bg-purple-200 text-purple-800'
                        : 'bg-gray-200 text-gray-600'
                    } hover:opacity-80 transition-opacity`}
                  aria-label={`問題${index + 1}へ移動`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}

          {/* 現在の質問情報 */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                {questions[currentQuestionIndex] && (
                  <>
                    <span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm">
                      {getSubjectName(questions[currentQuestionIndex].subject)}
                    </span>
                    <span className="text-sm text-gray-500">
                      {questions[currentQuestionIndex].contentTitle}
                    </span>
                  </>
                )}
              </div>
              {timeLimit > 0 && (
                <div className={`text-sm font-semibold ${
                  timeRemaining === 0 ? 'text-red-600' : 'text-gray-600'
                }`}>
                  {timeRemaining === 0 ? '時間切れ' : `残り時間: ${timeRemaining}秒`}
                </div>
              )}
            </div>

{/* 質問のレンダリング */}
            {renderQuestion()}
          </div>

          {/* フィードバックの表示 */}
          {showFeedback && sessionResponses[questions[currentQuestionIndex]?.id] && (
            <div
              className={`p-4 rounded-lg mb-6 ${
                sessionResponses[questions[currentQuestionIndex].id].isCorrect
                  ? 'bg-green-50 border border-green-200'
                  : 'bg-red-50 border border-red-200'
              }`}
            >
              <div className="flex items-center gap-2 mb-2">
                {sessionResponses[questions[currentQuestionIndex].id].isCorrect ? (
                  <Check className="text-green-600" />
                ) : (
                  <AlertTriangle className="text-red-600" />
                )}
                <span
                  className={`font-semibold ${
                    sessionResponses[questions[currentQuestionIndex].id].isCorrect
                      ? 'text-green-600'
                      : 'text-red-600'
                  }`}
                >
                  {sessionResponses[questions[currentQuestionIndex].id].isCorrect ? '正解です！' : '不正解です'}
                </span>
              </div>
              {sessionResponses[questions[currentQuestionIndex].id].explanation && (
                <p className="text-gray-600 whitespace-pre-line">
                  {sessionResponses[questions[currentQuestionIndex].id].explanation}
                </p>
              )}
              <p className="text-gray-600 mt-2">
                正解: {sessionResponses[questions[currentQuestionIndex].id].correctAnswer}
              </p>
            </div>
          )}

          {/* 最終まとめの表示 */}
          {showFinalSummary && (
            <div className="p-4 mt-4 bg-pink-50 rounded border border-pink-200">
              <h3 className="text-xl font-semibold text-purple-700 mb-4">全問題の結果</h3>

{/* 正答率サマリー */}
              <div className="mb-6 p-4 bg-white rounded shadow">
                <div className="grid grid-cols-3 gap-4">
                  <div className="text-purple-600">
                    正答率: {(Object.values(sessionResponses).filter(r => r.isCorrect).length / questions.length * 100).toFixed(1)}%
                  </div>
                  <div className="text-purple-600">
                    正解数: {Object.values(sessionResponses).filter(r => r.isCorrect).length}/{questions.length}
                  </div>
                  <div className="text-purple-600">
                    所要時間: {formatElapsedTime(Date.now() - startTime)}
                  </div>
                </div>
              </div>

              {/* 各問題の詳細結果 */}
              <div className="space-y-4">
                {questions.map((question, index) => {
                  const response = sessionResponses[question.id];
                  if (!response) return null;

                  return (
                    <div key={question.id} className="bg-white p-4 rounded-lg shadow">
                      {/* 問題番号と正誤表示 */}
                      <div className="mb-2">
                        <div className="flex justify-between items-center">
                          <span className="font-medium text-gray-700">問題 {index + 1}</span>
                          <span className={`font-semibold ${response.isCorrect ? 'text-blue-600' : 'text-red-600'}`}>
                            {response.isCorrect ? '✓ 正解' : 
                             (response.explanation === "時間切れです。" ? "× 時間切れ" : "× 不正解")}
                          </span>
                        </div>
                      </div>

                      {/* 問題の詳細表示 */}
                      <div className="space-y-2">

{/* 問題文 */}
                        {question.type !== 'sentence-rearrangement' && (
                          <p className="text-gray-800">
                            <span className="font-medium">問題: </span>
                            {question.question}
                          </p>
                        )}

                        {/* 問題タイプ別の詳細表示 */}
                        {question.type === 'multiple-choice' && (
                          <div className="ml-4">
                            <p className="text-sm text-gray-600">選択肢:</p>
                            <ul className="list-disc ml-4">
                              {question.choices.map((choice, idx) => (
                                <li key={idx} className={`
                                  ${choice === question.answer ? 'text-blue-600 font-semibold' : ''}
                                  ${choice === response.userAnswer && choice !== question.answer ? 'text-green-600' : ''}
                                `}>
                                  {choice}
                                  {choice === question.answer && ' (正解)'}
                                  {choice === response.userAnswer && choice !== question.answer && ' (あなたの回答)'}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {question.type === 'sentence-rearrangement' && (
                          <>
                            <p className="text-gray-700 mb-2">
                              <span className="font-medium">問題: </span>
                              {question.japaneseTranslation}
                            </p>
                            <p className="mb-2">
                              <span className="font-medium text-gray-700">正解: </span>
                              <span className="text-blue-600">{question.englishSentence}</span>
                            </p>
                            <p>
                              <span className="font-medium text-gray-700">あなたの回答: </span>
                              <span className="text-green-600">
                                {response.explanation === "時間切れです。" ? 
                                  "時間切れ" : 
                                  (response.userAnswer || '回答なし')}
                              </span>
                            </p>
                          </>
                        )}

{question.type === 'true-false' && (
                          <>
                            <p>
                              <span className="font-medium text-gray-700">正解: </span>
                              <span className="text-blue-600">{question.answer ? '正しい' : '誤り'}</span>
                            </p>
                            <p>
                              <span className="font-medium text-gray-700">あなたの回答: </span>
                              <span className="text-green-600">
                                {response.explanation === "時間切れです。" ? 
                                  "時間切れ" : 
                                  (response.userAnswer ? '正しい' : '誤り')}
                              </span>
                            </p>
                          </>
                        )}

                        {question.type === 'fill-in-the-blank' && (
                          <>
                            <p>
                              <span className="font-medium text-gray-700">正解: </span>
                              <span className="text-blue-600">{question.answer}</span>
                            </p>
                            <p>
                              <span className="font-medium text-gray-700">あなたの回答: </span>
                              <span className="text-green-600">
                                {response.explanation === "時間切れです。" ? 
                                  "時間切れ" : 
                                  response.userAnswer}
                              </span>
                            </p>
                          </>
                        )}

                        {question.type === 'matching' && (
                          <>
                            <p className="font-medium text-gray-700 mb-2">マッチング:</p>
                            <div className="ml-4">
                              {question.leftItems.map((leftItem, idx) => (
                                <div key={idx} className="flex gap-4">
                                  <span>{leftItem}</span>
                                  <span>→</span>
                                  <span>
                                    {response.explanation === "時間切れです。" ? 
                                      "時間切れ" : (
                                        <>
                                          <span className="text-green-600">
                                            {question.rightItems[response.userAnswer?.[idx] || 0]}
                                          </span>
                                          {question.correctMatches[idx] !== response.userAnswer?.[idx] && 
                                            <span className="text-blue-600">
                                              {` (正解: ${question.rightItems[question.correctMatches[idx]]})`}
                                            </span>
                                          }
                                        </>
                                      )
                                    }
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        )}

{/* 解説 */}
                        {response.explanation && (
                          <div className="mt-2 text-gray-600 bg-gray-50 p-2 rounded">
                            <span className="font-medium">解説: </span>
                            {response.explanation === "時間切れです。" ? 
                              "時間切れのため、解答できませんでした。" : 
                              response.explanation}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* 閉じるボタンのコンテナ */}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                  aria-label="結果を閉じるボタン"
                >
                  閉じる
                </button>
              </div>
            </div>
          )}

          {/* ナビゲーションボタン */}
          {questions.length > 0 && (
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => {
                  if (freeNavigation) {
                    setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
                    setShowFeedback(false);
                    scrollToTop();
                  }
                }}
                disabled={currentQuestionIndex === 0 || !freeNavigation}
                className={`flex items-center gap-2 px-4 py-2 rounded
                  ${(currentQuestionIndex === 0 || !freeNavigation)
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'text-purple-600 hover:text-purple-800'}`}
                aria-label="前の問題に戻るボタン"
              >
                <ArrowLeft size={20} />
                前の問題
              </button>
              <button
                onClick={() => {
                  setCurrentQuestionIndex((prev) => Math.min(prev + 1, questions.length - 1));
                  setShowFeedback(false);
                  scrollToTop();
                  if (!freeNavigation && timeLimit > 0) {
                    startTimer(timeLimit);
                  }
                }}
                disabled={
                  currentQuestionIndex === questions.length - 1 ||
                  (!freeNavigation && !sessionResponses[questions[currentQuestionIndex]?.id])
                }
                className={`flex items-center gap-2 px-4 py-2 rounded
                  ${(currentQuestionIndex === questions.length - 1 ||
                    (!freeNavigation && !sessionResponses[questions[currentQuestionIndex]?.id]))
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'text-purple-600 hover:text-purple-800'}`}
                aria-label="次の問題に進むボタン"
              >
                次の問題
                <ArrowRight size={20} />
              </button>
            </div>
          )}
        </div>
      </div>
    )}


  </div>
)}






    </div>
  );
};

QuizSection.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object,
  isTeacher: PropTypes.bool.isRequired,
};

export default QuizSection;
